import { Box, Text } from '@wix/design-system';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { dataHooks } from '../../dataHooks';
import { ROUTES } from '../../routes';
import { ISignupFlows, SIGNUP_FLOWS } from '../../stores/signup';
import { keys } from '../../translationsKeys';
import { AppContextProvider } from '../AppLoader';
import {
  AuthDivider,
  AuthPage,
  AuthPageContextProvider,
  BodyContainer,
} from '../AuthPage/AuthPage';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { InputFieldWithLabel } from '../InputField/InputField';
import { removeGOT, renderGoogleOT } from '../OneTap/GoogleOT';
import { PasswordFieldWithLabel } from '../PasswordField/PasswordField';
import { PoliciesContainer, PoliciesVerticalContainer } from '../PoliciesContainer';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import { SocialAuthIconsWithApple } from '../SocialAuth/SocialAuth';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import s from './Signup.scss';

export const Signup: React.FC = observer(() => {
  const {
    rootStore: { signupStore, displayStore },
  } = useContext(AppContextProvider);

  // Should apply "autoFocus" only after wsr fixes their bug in InputWithLabel https://wix.monday.com/boards/2436042988/pulses/3945043595
  useEffect(() => {
    if (!displayStore.isMobile && !displayStore.isVerticalLayout) {
      signupStore.isEmailSignupMode = false;
    }
    signupStore.shouldDisplayOneTap && renderGoogleOT('signup_page');
  }, [displayStore.isMobile, signupStore, signupStore.shouldDisplayOneTap]);

  useEffect(() => {
    return () => {
      signupStore.shouldDisplayOneTap && removeGOT();
    };
  }, []);

  return (
    <AuthPageContextProvider.Provider value={{ store: signupStore }}>
      <Box
        direction="vertical"
        width="100%"
        align="center"
        marginBottom={!displayStore.isMobile ? '40px' : undefined}
      >
        <AuthPage
          container={{
            dataHook: dataHooks.login.container,
            mainHeaderProps: {
              showBackButton:
                signupStore.flow === SIGNUP_FLOWS.FROM_LOGIN ||
                signupStore.isEmailSignupMode,
              backButtonCallback: signupStore.onBackClicked,
            },
          }}
        >
          {displayStore.isVerticalLayout ? (
            <VerticalLayoutSignup />
          ) : (
            <HorizontalLayoutSignup />
          )}
        </AuthPage>
        <AuthPage.Policies>
          {!displayStore.isVerticalLayout && <PoliciesContainer.Expanded />}
        </AuthPage.Policies>
      </Box>
    </AuthPageContextProvider.Provider>
  );
});

const EmailSignup: React.FC<{ shouldFocusFirstEmail?: boolean }> = observer(
  ({ shouldFocusFirstEmail }) => {
    const {
      rootStore: { signupStore, displayStore, socialAuthStore },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const shouldRepeatPassword = !displayStore.isMobile;
    const inputBorder = !displayStore.isVerticalLayout
      ? 'bottomLine'
      : 'standard';

    useEffect(() => {
      return () => {
        signupStore.clear();
        signupStore.flow = SIGNUP_FLOWS.DEFAULT;
      };
    }, []);

    return (
      <Box
        direction={displayStore.sideBySideForm.direction}
        width={displayStore.sideBySideForm.width}
      >
        <Box
          width={displayStore.socialButtonsWidth}
          direction="vertical"
          verticalAlign="middle"
          textAlign="left"
          gap={displayStore.isVerticalLayout ? 0 : 1}
        >
          <InputFieldWithLabel
            {...(displayStore.isWixel && {
              className: classNames(s.inputField, s.wixel),
            })}
            required
            autoFocus={shouldFocusFirstEmail}
            onBlur={() => {
              signupStore.updateEmailFieldMatchError();
            }}
            onChange={signupStore.emailField.onChange}
            dataHook={dataHooks.signup.email}
            formField={signupStore.emailField}
            label={t(keys['signup.email.label'])}
            type="text"
            autocomplete="email"
            marginBottom={0}
            border={inputBorder}
          />
          <InputFieldWithLabel
            {...(displayStore.isWixel && {
              className: classNames(s.inputField, s.wixel),
            })}
            required
            autoFocus={!shouldFocusFirstEmail}
            disablePaste
            onChange={signupStore.emailRepeatField.onChange}
            onBlur={() => {
              signupStore.updateEmailFieldMatchError();
            }}
            dataHook={dataHooks.signup.confirmEmail}
            formField={signupStore.emailRepeatField}
            label={t(keys['signup.emailRepeat.label'])}
            type="text"
            autocomplete="email"
            marginBottom={0}
            border={inputBorder}
          />
          <PasswordFieldWithLabel
            {...(displayStore.isWixel && {
              className: classNames(s.inputField, s.wixel),
            })}
            required
            onBlur={() => {
              signupStore.updatePasswordFieldMatchError();
            }}
            dataHook={dataHooks.signup.password}
            formField={signupStore.passwordField}
            onChange={signupStore.passwordField.onChange}
            label={t(keys['signup.password.label'])}
            autocomplete="new-password"
            showStrength
            isMobile={displayStore.isMobile}
            showVisibility={!!signupStore.passwordField.value.length}
            value={signupStore.passwordField.value}
            marginBottom={0}
            border={inputBorder}
            onFocus={(e) => {
              if (displayStore.isMobile) {
                e.target.scrollIntoView();
              }
            }}
          />
          {shouldRepeatPassword && (
            <PasswordFieldWithLabel
              {...(displayStore.isWixel && {
                className: classNames(s.inputField, s.wixel),
              })}
              required
              disablePaste
              onBlur={() => signupStore.updatePasswordFieldMatchError()}
              dataHook={dataHooks.signup.confirmPassword}
              formField={signupStore.passwordRepeatField}
              label={t(keys['signup.passwordRepeat.label'])}
              autocomplete="new-password"
              showVisibility={!!signupStore.passwordRepeatField.value.length}
              value={signupStore.passwordRepeatField.value}
              marginBottom={0}
              border={inputBorder}
              onEnterPressed={signupStore.signup}
            />
          )}
          <Recaptcha/>
          <Box
            marginBottom={1}
            width={displayStore.isVerticalLayout ? '100%' : 'auto'}
            marginTop={socialAuthStore.isUnsupportedAgentForSocialAuth ? 4 : 0}
            align={
              socialAuthStore.isUnsupportedAgentForSocialAuth
                ? 'center'
                : 'space-between'
            }
            direction={
              socialAuthStore.isUnsupportedAgentForSocialAuth ||
              displayStore.isVerticalLayout
                ? 'vertical'
                : 'horizontal'
            }
          >
            <Box
              direction="vertical"
              width={displayStore.isVerticalLayout ? '100%' : 'auto'}
              marginBottom={4}
              marginTop={displayStore.isVerticalLayout ? 2 : 0}
            >
              <ButtonWithLoader
                className={classNames(s.a11yFocus, s.border)}
                type="submit"
                onMouseDown={
                  displayStore.isMobile ? signupStore.signup : undefined
                }
                onClick={displayStore.isMobile ? undefined : signupStore.signup}
                dataHook={dataHooks.signup.submit}
                showLoader={signupStore.isLoading}
                showArrowOnOver={displayStore.isVerticalLayout}
                priority={
                  displayStore.isVerticalLayout ? 'primary' : 'secondary'
                }
                skin={displayStore.preset.signup?.primaryButtonDesign?.skin}
              >
                {t(keys['signup.submit'])}
              </ButtonWithLoader>
            </Box>
            {displayStore.isVerticalLayout ? (
              <Box direction="vertical" verticalAlign="top">
                <AuthDivider text={t('emailStep.divider.signup')}/>
                <SocialAuthIconsWithApple/>
              </Box>
            ) : (
              <>
                {displayStore.isMobile &&
                  !socialAuthStore.isUnsupportedAgentForSocialAuth && (
                    <Box verticalAlign="middle" marginBottom={2}>
                      <Text size="small">
                        {t(keys['signup.divider.mobile'])}
                      </Text>
                    </Box>
                  )}
                {displayStore.isMobile && (
                  <Box direction="vertical" verticalAlign="top">
                    <SocialAuthIconsWithApple/>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

const SignupSubTitle: React.FC<{ flow: ISignupFlows }> = ({ flow }) => {
  const location = useLocation();
  const {
    rootStore: {
      signupStore,
      displayStore: { isVerticalLayout, isWixel },
      emailStepStore: { loginEmailStepStore },
    },
  } = useContext(AppContextProvider);

  if (flow === SIGNUP_FLOWS.DEFAULT) {
    return (
      <ThemedText
        className={classNames({
          [s.subtitleSmall]: isVerticalLayout,
        })}
      >
        <Trans i18nKey={keys['emailStep.signup.subTitle']}>
          Already have an account?
          <ThemedTextButton
            className={classNames(s.link, {
              [s.wixel]: isWixel,
            })}
            onClick={() => {
              loginEmailStepStore.onShowLoginEmailStep();
              signupStore.reportSwitchToLogin();
              signupStore.isEmailSignupMode = false;
            }}
            as={Link}
            to={ROUTES.EMAIL_STEP + location.search}
            dataHook={dataHooks.signup.switchToLoginBtn}
            skin="standard"
            underline={isVerticalLayout ? 'always' : 'onHover'}
          >
            Log In
          </ThemedTextButton>
        </Trans>
      </ThemedText>
    );
  }

  return <></>;
};

const HorizontalLayoutSignup: FC = observer(() => {
  const {
    rootStore: { signupStore, displayStore },
  } = useContext(AppContextProvider);

  const { t } = useTranslation();
  // Should apply "autoFocus" only after wsr fixes their bug in InputWithLabel https://wix.monday.com/boards/2436042988/pulses/3945043595
  const shouldFocusFirstEmail = signupStore.flow === SIGNUP_FLOWS.DEFAULT;

  return (
    <>
      <AuthPage.Header dataHooks={dataHooks.signup}>
        <SignupSubTitle flow={signupStore.flow} />
      </AuthPage.Header>
      {signupStore.isEmailSignupMode ? (
        <EmailSignup shouldFocusFirstEmail={shouldFocusFirstEmail} />
      ) : (
        <AuthPage.BodyWithSocialButtons
          dividerText={t(
            keys[
              displayStore.isMobile
                ? 'signup.divider.use.email.mobile'
                : 'signup.divider'
            ],
          )}
        >
          {displayStore.isMobile ? ( // Show only email field on mobile
            <Box
              direction={displayStore.sideBySideForm.direction}
              width={displayStore.sideBySideForm.width}
            >
              <Box
                width={displayStore.socialButtonsWidth}
                direction="vertical"
                verticalAlign="middle"
              >
                <InputFieldWithLabel
                  required
                  onFocus={() => {
                    signupStore.isEmailSignupMode = true;
                  }}
                  label={t(keys['signup.email.label'])}
                  type="email"
                  autocomplete="email"
                  marginBottom={0}
                  border="bottomLine"
                />
              </Box>
            </Box>
          ) : (
            <EmailSignup shouldFocusFirstEmail={shouldFocusFirstEmail} />
          )}
        </AuthPage.BodyWithSocialButtons>
      )}
    </>
  );
});

const VerticalLayoutSignup: FC = observer(() => {
  const {
    rootStore: {
      signupStore,
      displayStore: {
        isMobile,
        socialButtonsWidth,
        preset,
        isVerticalLayout,
        authFormWidth,
        isWixel
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      signupStore.clear();
      signupStore.flow = SIGNUP_FLOWS.DEFAULT;
    };
  }, []);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dividerText = useMemo(() => {
    return t(keys['emailStep.wixel.signup.divider']);
  }, [isVerticalLayout, isMobile, t]);
  const submit = useCallback(() => {
    signupStore.emailField.triggerValidator();
    if (!signupStore.emailField.isValid) {
      signupStore.emailField.markFieldAsDirty();
      buttonRef.current?.focus();
      return;
    }
    signupStore.handleSubmit();
  }, [signupStore.emailField]);

  return (
    <BodyContainer withPolicies>
      <AuthPage.Header dataHooks={dataHooks.signup}>
        <SignupSubTitle flow={signupStore.flow} />
      </AuthPage.Header>
      <Box
        direction="vertical"
        gap={2}
        width={socialButtonsWidth}
        maxWidth={authFormWidth}
        align="center"
      >
        {signupStore.isEmailSignupMode ? (
          <EmailSignup shouldFocusFirstEmail={false} />
        ) : (
          <AuthPage.VerticalBody dividerText={dividerText}>
            <Box
              width={socialButtonsWidth}
              direction="vertical"
              verticalAlign="middle"
              align="center"
            >
              <InputFieldWithLabel
                required
                formField={signupStore.emailField}
                label={t(keys['signup.email.label'])}
                {...(isWixel && {
                  className: classNames(s.inputField, s.wixel),
                })}
                type="email"
                autocomplete="email"
                marginBottom={0}
                onEnterPressed={submit}
              />
              <Box direction="vertical" gap={2} width="100%">
                <Box marginTop={2} width="100%">
                  <ButtonWithLoader
                    ref={buttonRef}
                    className={classNames(s.submitButton, s.a11yFocus, s.border, {
                      [s.wixel]: isWixel,
                    })}
                    fullWidth
                    size="large"
                    skin={preset.signup?.primaryButtonDesign?.skin}
                    showArrowOnOver={true}
                    onClick={submit}
                    showLoader={signupStore.isLoading}
                  >
                    {preset.signup?.primaryButtonDesign?.textKey &&
                      t(preset.signup?.primaryButtonDesign?.textKey)}
                  </ButtonWithLoader>
                </Box>
              </Box>
            </Box>
          </AuthPage.VerticalBody>
        )}
      </Box>
      <PoliciesVerticalContainer>
        <PoliciesContainer.Expanded />
      </PoliciesVerticalContainer>
    </BodyContainer>
  );
});
