import { Box, TextButton } from '@wix/design-system';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { dataHooks } from '../../dataHooks';
import { ROUTES } from '../../routes';
import { ILoginFlows, LOGIN_FLOWS } from '../../stores/login';
import { keys } from '../../translationsKeys';
import { PASSWORD_MIN_LENGTH_OLD } from '../../utils/validators';
import { AppContextProvider } from '../AppLoader';
import {
  AuthPage,
  AuthPageContextProvider,
  BodyContainer,
} from '../AuthPage/AuthPage';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { InputFieldWithLabel } from '../InputField/InputField';
import { PasswordFieldWithLabel } from '../PasswordField/PasswordField';
import { PoliciesContainer, PoliciesVerticalContainer } from '../PoliciesContainer';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import s from './Login.scss';

const Login: React.FC = observer(() => {
  const {
    rootStore: {
      displayStore: { isMobile },
    },
  } = useContext(AppContextProvider);

  if (isMobile) {
    return <Mobile />;
  }
  return <Desktop />;
});

export const LoginWrapper: React.FC = observer(({ children }) => {
  const {
    rootStore: {
      loginStore,
      displayStore: { isMobile, isVerticalLayout },
    },
  } = useContext(AppContextProvider);

  return (
    <AuthPageContextProvider.Provider value={{ store: loginStore }}>
      <Box
        direction="vertical"
        width="100%"
        align="center"
        marginBottom={!isMobile ? '40px' : undefined}
      >
        <AuthPage
          container={{
            dataHook: dataHooks.login.container,
            mainHeaderProps: {
              showBackButton: loginStore.flow === LOGIN_FLOWS.FROM_SIGNUP,
            },
          }}
        >
          <BodyContainer priority={loginStore.priority} withPolicies>{children}</BodyContainer>
        </AuthPage>
        <AuthPage.Policies>
          {!isVerticalLayout && <PoliciesContainer.Minimal />}
        </AuthPage.Policies>
      </Box>
    </AuthPageContextProvider.Provider>
  );
});

const Mobile: React.FC = observer(() => {
  const {
    rootStore: {
      loginStore,
      displayStore: { isVerticalLayout },
    },
  } = useContext(AppContextProvider);
  return (
    <LoginWrapper>
      {isVerticalLayout ? (
        <VerticalLayoutLogin />
      ) : (
        <>
          <AuthPage.Header dataHooks={dataHooks.login}>
            <ThemedText>
              <LoginSubTitle flow={loginStore.flow} />
            </ThemedText>
          </AuthPage.Header>
          <AuthPage.MobileBody>
            <LoginForm gap={3} marginTop={9} />
          </AuthPage.MobileBody>
        </>
      )}
    </LoginWrapper>
  );
});

const Desktop: React.FC = observer(() => {
  const {
    rootStore: {
      displayStore: { isVerticalLayout },
    },
  } = useContext(AppContextProvider);
  return (
    <LoginWrapper>
      {isVerticalLayout ? <VerticalLayoutLogin /> : <HorizontalLayoutLogin />}
    </LoginWrapper>
  );
});

const HorizontalLayoutLogin: React.FC = observer(() => {
  const {
    rootStore: { loginStore },
  } = useContext(AppContextProvider);
  return (
    <>
      <AuthPage.Header dataHooks={dataHooks.login}>
        <ThemedText>
          <LoginSubTitle flow={loginStore.flow} />
        </ThemedText>
      </AuthPage.Header>
      <AuthPage.Body>
        <LoginForm />
      </AuthPage.Body>
    </>
  );
});

const VerticalLayoutLogin: React.FC = observer(() => {
  const {
    rootStore: {
      loginStore,
      emailStepStore,
      displayStore: { socialButtonsWidth },
      ssoStore: { isSSOEnabledForFunnel },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <>
      <Box direction="vertical" align="center">
        <AuthPage.Header dataHooks={dataHooks.signup}>
          <LoginSubTitle flow={loginStore.flow} />
        </AuthPage.Header>
        <AuthPage.VerticalBody socialFirst={false}>
          <LoginForm gap={0} marginTop={0} />
        </AuthPage.VerticalBody>
        {loginStore.flow !== LOGIN_FLOWS.FROM_SIGNUP &&
          isSSOEnabledForFunnel && (
            <Box width={socialButtonsWidth} align="center">
              <TextButton
                className={s.a11yFocus}
                onClick={emailStepStore.onClickContinueWithSso}
                size="small"
                underline="none"
                dataHook={dataHooks.emailStep.continueToSsoLink}
              >
                {t(keys['emailStep.sso.button'])}
              </TextButton>
            </Box>
          )}
      </Box>
      <PoliciesVerticalContainer>
        <PoliciesContainer.Minimal />
      </PoliciesVerticalContainer>
    </>
  );
});

const LoginForm: React.FC<{
  showEmail?: boolean;
  gap?: number;
  marginTop?: number;
}> = observer(({ showEmail = true, gap, marginTop }) => {
  const {
    rootStore: {
      loginStore,
      emailStepStore,
      displayStore: {
        sideBySideForm,
        isMobile,
        socialButtonsWidth,
        preset,
        isVerticalLayout,
        authFormWidth,
        isWixel
      },
    },
  } = useContext(AppContextProvider);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const onEnterPressed = useCallback(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.focus();
      submitButtonRef.current.click();
      return;
    }
    loginStore.login();
  }, []);
  const inputBorder =
    preset.container?.layout === 'vertical' ? 'standard' : 'bottomLine';
  const shouldFocusEmail = !loginStore.emailField.value?.length;
  const isMobileOrVertical = isMobile || isVerticalLayout;
  const isMobileAndVertical = isMobile && isVerticalLayout;
  const primaryButtonSkin =
    preset.login?.primaryButtonDesign?.skin ??
    (isMobile ? 'standard' : 'inverted');
  return (
    <Box
      direction={sideBySideForm.direction}
      width={isMobileAndVertical ? authFormWidth : sideBySideForm.width}
      align={isVerticalLayout ? 'center' : undefined}
    >
      <Box
        width={socialButtonsWidth}
        direction="vertical"
        verticalAlign="middle"
        gap={gap}
        marginTop={marginTop}
      >
        {showEmail && (
          <InputFieldWithLabel
            {...(isWixel && {
              className: classNames(s.inputField, s.wixel),
            })}
            required
            autoFocus={shouldFocusEmail}
            formField={loginStore.emailField}
            label={t(keys['login.email.label'])}
            type="email"
            autocomplete="email"
            clearButton
            onClear={() => loginStore.clearEmail()}
            marginBottom={0}
            dataHook={dataHooks.login.emailInput}
            border={inputBorder}
          />
        )}
        <PasswordFieldWithLabel
          required
          autoFocus={!shouldFocusEmail}
          formField={loginStore.passwordField}
          label={t(keys['login.password.label'])}
          autocomplete="current-password"
          showVisibility={!!loginStore.passwordField.value.length}
          value={loginStore.passwordField.value}
          marginBottom={0}
          dataHook={dataHooks.login.passwordInput}
          minLength={PASSWORD_MIN_LENGTH_OLD}
          border={inputBorder}
          onEnterPressed={onEnterPressed}
        />
        <Box>
          <ThemedTextButton
            className={s.a11yFocus}
            onClick={loginStore.onClickForgotPassword}
            dataHook={dataHooks.login.forgotPassword}
            size="small"
            underline="always"
            as="a"
            href={emailStepStore.getAccountRecoveryLink('password')}
          >
            {t(keys['login.forgotPassword.button'])}
          </ThemedTextButton>
        </Box>
        <Box
          marginTop={isVerticalLayout ? 3 : 4}
          marginBottom={isVerticalLayout ? "14px" : 0}
          gap={1}
          direction="vertical"
          align={!isMobileOrVertical ? 'left' : undefined}
        >
          <Recaptcha />
          <ButtonWithLoader
            style={{
              backgroundColor: preset.signup?.primaryButtonDesign?.color,
            }}
            ref={submitButtonRef}
            className={classNames(s.a11yFocus, s.border, s.submitButton)}
            type="submit"
            onClick={loginStore.login}
            dataHook={dataHooks.login.submitButton}
            showLoader={loginStore.isLoading}
            showArrowOnOver={isVerticalLayout}
            skin={primaryButtonSkin}
            fullWidth={isMobileOrVertical}
          >
            {t(keys['login.submit'])}
          </ButtonWithLoader>
        </Box>
      </Box>
    </Box>
  );
});

export const LoginSubTitle: React.FC<{ flow: ILoginFlows }> = ({ flow }) => {
  const location = useLocation();
  const {
    rootStore: {
      loginStore,
      displayStore: { isVerticalLayout, isWixel },
    },
  } = useContext(AppContextProvider);
  const shouldBlockSignup = loginStore.shouldBlockEditorXSignup();
  if (!shouldBlockSignup && flow === LOGIN_FLOWS.DEFAULT) {
    return (
      <ThemedText
        className={classNames(s.subtitle, { [s.vertical]: isVerticalLayout })}
      >
        <Trans i18nKey={keys['emailStep.login.subTitle']}>
          Want to create an account?
          <ThemedTextButton
            className={classNames(s.link, {
              [s.wixel]: isWixel,
            })}
            as={Link}
            to={ROUTES.SIGNUP_PASSWORD + location.search}
            dataHook={dataHooks.login.switchToSignupBtn}
            onClick={() => loginStore.reportSwitchToSignup()}
            skin="standard"
            underline={isVerticalLayout ? 'always' : 'onHover'}
          >
            Sign Up
          </ThemedTextButton>
        </Trans>
      </ThemedText>
    );
  }

  return <></>;
};

export default Login;
