import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  cloneElement,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { InputFieldWithLabel } from '../InputField';
import { Box, Checkbox } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import {
  TwoFactorAuthPhoneDeliveryMethod,
  TwoFactorAuthPhoneDeliveryMethods,
  TwoFactorAuthMethods,
} from '../../utils/constants';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { AuthenticateByCodeStore } from '../../stores/authenticateByCode';
import ActionElement from '../ActionButton/ActionElement';
import { EnterCodeStore, TWO_FA_METHODS_MAP } from '../../stores/enterCode';
import { loginTwoFactorAuthenticationCallMeCodeClick } from '@wix/bi-logger-hls2/v2';
import { DialogFormContainer } from '../DialogContainer';
import {
  ThemedButton,
  ThemedTextButton,
  ThemedText,
} from '../ThemedComponents';
import { ActionElementStore } from '../../stores/actionElement';
import s from './EnterCode.scss';
import { BodyContainer } from '../AuthPage/AuthPage';
import { ButtonWithLoader, BEHAVIORS } from '../ButtonWithLoader/ButtonWithLoader';
import classNames from "classnames";

interface EnterCodeProps {
  dialogTitleKey: string;
  descriptionKey: string;
  store: AuthenticateByCodeStore;
  showBackButton?: boolean;
}

interface EnterCodeContext {
  store?: AuthenticateByCodeStore;
  enterCodeStore?: EnterCodeStore;
}

export const EnterCodeContextProvider = createContext<EnterCodeContext>({});

const EnterCode: React.FC<EnterCodeProps> = observer(
  ({
    dialogTitleKey,
    descriptionKey,
    store,
    children,
    showBackButton = true,
  }) => {
    const { t } = useTranslation();
    const { enterCodeStore } = useContext(EnterCodeContextProvider);
    const {
      rootStore: {
        displayStore: { isMobile, isVerticalLayout, isWixel },
      },
    } = useContext(AppContextProvider);

    return (
      <DialogFormContainer
        dataHook={dataHooks.enterCode.container}
        formClasses={[s.form]}
        mainHeaderProps={{
          showBackButton,
          backButtonCallback: () =>
            enterCodeStore?.onBackButtonClicked(store.currentTwoFAMethod),
        }}
      >
        <BodyContainer priority="secondary">
          <DialogHeader>
            <DialogHeader.Title size="large" flow="secondaryFlow">
              {t(dialogTitleKey)}
            </DialogHeader.Title>
            <DialogHeader.SubTitleSmall className={s.description}>
              <Trans
                i18nKey={descriptionKey}
                values={{ twoFAHint: store.twoFAHint }}
              >
                Enter the 6-digit code sent to
                <ThemedText weight="bold">***</ThemedText>
              </Trans>
            </DialogHeader.SubTitleSmall>
          </DialogHeader>
          <Box
            direction="vertical"
            align={isVerticalLayout ? 'center' : 'left'}
            className={s.codeInput}
            gap={0}
          >
            <InputFieldWithLabel
              {...(isWixel && {
                className: classNames(s.inputField, s.wixel),
              })}
              required
              autoFocus={!isMobile}
              formField={store?.codeField}
              label={t('enter_code.code_input_placeholder')}
              type="text"
              dataHook={dataHooks.enterCode.codeInput}
            />
            {children}
          </Box>
        </BodyContainer>
      </DialogFormContainer>
    );
  },
);

const MethodsSwitch: React.FC = observer(() => {
  const { t } = useTranslation();
  const { store, enterCodeStore } = useContext(EnterCodeContextProvider);

  return (
    <Box
      align="center"
      verticalAlign="middle"
      marginTop="10px"
      direction="vertical"
      gap="5px"
    >
      {store?.enabledTwoFAMethods?.map(
        (method) =>
          method !== store.currentTwoFAMethod && (
            <ThemedTextButton
              underline="always"
              size="small"
              onClick={() => enterCodeStore?.onSwitchMethod(method, store)}
            >
              {t(`enter_code.${TWO_FA_METHODS_MAP[method]}.switch_method`)}
            </ThemedTextButton>
          ),
      )}
    </Box>
  );
});

const LostAccess: React.FC<{ onClick?: () => void }> = observer(
  ({ onClick }) => {
    const { t } = useTranslation();
    const { store } = useContext(EnterCodeContextProvider);
    return (
      <ThemedTextButton
        underline="always"
        onClick={() => onClick && onClick()}
        size="small"
      >
        {t(
          store?.currentTwoFAMethod === TwoFactorAuthMethods.Email
            ? 'enter_code.lost_access_to_email'
            : 'enter_code.lost_access_to_phone',
        )}
      </ThemedTextButton>
    );
  },
);

const RememberMe: React.FC = observer(() => {
  const { t } = useTranslation();
  const { enterCodeStore } = useContext(EnterCodeContextProvider);
  return (
    <Box width="100%" align="left">
      <Checkbox
        size="small"
        onChange={() => enterCodeStore?.onToggleRememberThisDevice()}
        checked={enterCodeStore?.rememberMe}
      >
        {t('enter_code.remeber_this_device')}
      </Checkbox>
    </Box>
  );
});

const VerifyButton: React.FC = observer(({ children }) => {
  const {
    rootStore: {
      displayStore: { isWixel },
    },
  } = useContext(AppContextProvider);
  const { store, enterCodeStore } = useContext(EnterCodeContextProvider);
  const { t } = useTranslation();
  return (
    <ButtonWithLoader
      dataHook={dataHooks.enterCode.verifyButton}
      size={undefined}
      type="submit"
      disabled={!store?.codeField.isValid}
      skin="standard"
      priority="primary"
      showArrowOnOver={isWixel}
      {...(isWixel && {
        className: classNames(s.a11yFocus),
      })}
      onClick={() =>
        store?.verify({
          code: store?.codeField.value!,
          rememberMe: enterCodeStore?.rememberMe,
        })
      }
      behaviour= {BEHAVIORS.ONE_TIME}
    >
      {children ?? t('enter_code.button')}
    </ButtonWithLoader>
  );
});

const VoiceCallLink: React.FC = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const [actionElementStore] = useState(new ActionElementStore(rootStore));
  return (
    <ActionElement
      store={actionElementStore}
      statusMessageDatahook={dataHooks.login.resendPopover}
    >
      <Resend
        phoneDeliveryMethod={TwoFactorAuthPhoneDeliveryMethods.VOICE_CALL}
        onResendCompleted={actionElementStore?.onActionCompleted}
      >
        <ThemedText size="small">
          <Trans i18nKey="enter_code.call_me_text">
            Didn’t receive an SMS?
            <ThemedTextButton
              size="small"
              skin="dark"
              underline="always"
              disabled={actionElementStore.showMessage}
            >
              Call me
            </ThemedTextButton>
          </Trans>
        </ThemedText>
      </Resend>
    </ActionElement>
  );
});

const ResendButton: React.FC<{
  onResendCompleted?: (errorCode?: string) => void;
  disabled?: boolean;
}> = observer(({ children, onResendCompleted, disabled }) => {
  const {
    rootStore: {
      displayStore: { isVerticalLayout },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Resend
      phoneDeliveryMethod={TwoFactorAuthPhoneDeliveryMethods.SMS}
      onResendCompleted={onResendCompleted}
    >
      <ThemedButton
        dataHook={dataHooks.enterCode.resendButton}
        skin="standard"
        priority="secondary"
        disabled={disabled}
      >
        {children ?? t('enter_code.resend_code')}
      </ThemedButton>
    </Resend>
  );
});

const Resend: React.FC<{
  children?: React.ReactElement;
  phoneDeliveryMethod?: TwoFactorAuthPhoneDeliveryMethod;
  onResendCompleted?: (errorCode?: string) => void;
}> = observer(({ children, phoneDeliveryMethod, onResendCompleted }) => {
  const { store } = useContext(EnterCodeContextProvider);
  const {
    rootStore: { biLogger },
  } = useContext(AppContextProvider);
  const onResendCode = useCallback(async () => {
    if (phoneDeliveryMethod === TwoFactorAuthPhoneDeliveryMethods.VOICE_CALL) {
      phoneDeliveryMethod === TwoFactorAuthPhoneDeliveryMethods.VOICE_CALL &&
        biLogger.report(loginTwoFactorAuthenticationCallMeCodeClick({}));
    }
    const errorCode = await store?.resendCode({ phoneDeliveryMethod });
    onResendCompleted?.(errorCode);
  }, [store, phoneDeliveryMethod, onResendCompleted, biLogger]);

  return (
    <>{children && cloneElement(children, { onClick: () => onResendCode() })}</>
  );
});

const _ButtonsSection: React.FC = ({ children }) => {
  return (
    <Box align="center" gap={2} marginTop={6} width="100%">
      {children}
    </Box>
  );
};

const _ButtonsSectionWithResend: React.FC = observer(({ children }) => {
  const { rootStore } = useContext(AppContextProvider);
  const [actionElementStore] = useState(new ActionElementStore(rootStore));
  return (
    <ActionElement
      store={actionElementStore}
      statusMessageDatahook={dataHooks.login.resendPopover}
    >
      <Box align="center" gap={2} marginTop={6} width="100%">
        <ResendButton
          onResendCompleted={actionElementStore?.onActionCompleted}
          disabled={actionElementStore.showMessage}
        />
        {children}
      </Box>
    </ActionElement>
  );
});

const LinksSection: React.FC = ({ children }) => {
  return (
    <Box
      alignSelf="center"
      align="center"
      direction="vertical"
      gap={1}
      marginTop={6}
    >
      {children}
    </Box>
  );
};
const ButtonsSection = Object.assign(_ButtonsSection, {
  ResendButton,
  VerifyButton,
});

const ButtonsSectionWithResend = Object.assign(_ButtonsSectionWithResend, {
  VerifyButton,
});

export default Object.assign(EnterCode, {
  VoiceCallLink,
  ButtonsSection,
  ButtonsSectionWithResend,
  RememberMe,
  MethodsSwitch,
  LostAccess,
  LinksSection,
});
