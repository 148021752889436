import { Box, HeadingProps, TextProps } from '@wix/design-system';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { ThemedHeading, ThemedText } from '../ThemedComponents';
import s from './DialogHeader.scss';
import { useHeadingSize } from './useHeadingSize';

export type DialogFlow = 'authPage' | 'secondaryFlow';

interface DialogHeaderProps {
  dataHook?: string;
  className?: string;
}

const _DialogHeader: React.FC<DialogHeaderProps> = observer(
  ({ dataHook, children, className }) => {
    const {
      rootStore: {
        displayStore: { isVerticalLayout },
      },
    } = useContext(AppContextProvider);
    return (
      <Box
        aria-live="assertive"
        aria-atomic="true"
        direction="vertical"
        gap={isVerticalLayout ? '3px' : 1}
        align="center"
        className={classNames(
          { [s.vertical]: isVerticalLayout },
          s.dialogHeaderContainer,
          className,
        )}
        dataHook={dataHook ?? dataHooks.dialogHeader.container}
      >
        {children}
      </Box>
    );
  },
);

export interface CustomHeadingProps extends HeadingProps {
  flow?: DialogFlow;
  customFontSize?: CustomFontSize;
}

export type CustomFontSize =
  | 'tiny'
  | 'small'
  | 'medium'
  | 'large'
  | 'xLarge'
  | 'xxLarge';

const Title: React.FC<CustomHeadingProps> = observer(
  ({ children, dataHook, className, flow, customFontSize, ...props }) => {
    const {
      rootStore: {
        displayStore: { isVerticalLayout, isMobile, isWixel },
      },
    } = useContext(AppContextProvider);
    const { fontSize, as } = useHeadingSize(customFontSize, flow);

    return (
      <ThemedHeading
        aria-live="assertive"
        aria-atomic="true"
        dataHook={dataHook}
        className={classNames(s.mainTitle, className, {
          [s.mobile]: isMobile,
          [s.vertical]: isVerticalLayout,
          [s.wixel]: isWixel,
        })}
        as={as}
        id={fontSize && s[`heading_${fontSize}`]}
        {...props}
      >
        {children}
      </ThemedHeading>
    );
  },
);

const SubTitleLarge: React.FC<{ dataHook?: string }> = observer(
  ({ children, dataHook }) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);
    return displayStore.isMobile ? (
      <ThemedText weight="normal">{children}</ThemedText>
    ) : (
      <ThemedHeading appearance="H4" dataHook={dataHook}>
        {children}
      </ThemedHeading>
    );
  },
);

const SubTitleSmall: React.FC<TextProps & { maxWidth?: string }> = ({
  children,
  dataHook,
  maxWidth,
  className,
  ...props
}) => {
  const {
    rootStore: { displayStore },
  } = useContext(AppContextProvider);
  return (
    <Box maxWidth={maxWidth}>
      <ThemedText
        dataHook={dataHook}
        className={classNames(className, s.subTitleSmall, {
          [s.vertical]: displayStore.isVerticalLayout,
        })}
        {...props}
      >
        {children}
      </ThemedText>
    </Box>
  );
};

export const DialogHeader = Object.assign(_DialogHeader, {
  SubTitleLarge,
  SubTitleSmall,
  Title,
});
