import { Box, Loader, Text, TextButton } from '@wix/design-system';
import { ChevronRight } from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { createContext, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { dataHooks } from '../../dataHooks';
import { ROUTES } from '../../routes';
import { LOGIN_FLOWS } from '../../stores/login';
import { keys } from '../../translationsKeys';
import { AppContextProvider } from '../AppLoader';
import {
  AuthDivider,
  AuthPage,
  AuthPageContextProvider,
  BodyContainer,
} from '../AuthPage/AuthPage';
import authPageStyle from '../AuthPage/AuthPage.scss';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { DialogFormContainer } from '../DialogContainer';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { InputFieldWithLabel } from '../InputField';
import { LoginSubTitle } from '../Login/Login';
import {
  PoliciesContainer,
  PoliciesVerticalContainer,
} from '../PoliciesContainer';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import { SocialAuth, SocialAuthIconsWithApple } from '../SocialAuth/SocialAuth';
import { ThemedTextButton } from '../ThemedComponents';
import s from './EmailStep.scss';

export interface EmailStepProps {
  showForgotEmailLink?: boolean;
  showContinueWithSsoLink?: boolean;
  policiesElement?: React.ReactNode;
}

interface EmailStepContext {
  store: AuthStore;
}

export const EmailStepContextProvider = createContext<EmailStepContext>(
  {} as any,
);

export const EmailStepHeader: React.FC = observer(({ children }) => {
  const { store } = useContext(EmailStepContextProvider);
  const { t } = useTranslation();

  return (
    <DialogHeader
      dataHook={dataHooks.emailStep.header}
      className={s.emailStepHeader}
    >
      <DialogHeader.Title
        flow="authPage"
        dataHook={dataHooks.emailStep.title}
        className={s.title}
      >
        {t(keys[store.titleKey])}
      </DialogHeader.Title>
      {children}
    </DialogHeader>
  );
});

const MobileEmailStepSocialLast: React.FC<EmailStepProps> = observer(
  ({ children }) => {
    const {
      rootStore: {
        displayStore: { sideBySideForm, isMobile },
        socialAuthStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <>
        {children}
        <Box
          gap={2}
          direction="horizontal"
          align="space-between"
          width={sideBySideForm.width}
          marginTop={3}
        >
          {!isMobile ||
            (!socialAuthStore.isUnsupportedAgentForSocialAuth && (
              <Box direction="vertical" verticalAlign="middle" marginBottom={3}>
                <Text>{t('emailStep.or.login.with')}</Text>
              </Box>
            ))}
          <SocialAuthIconsWithApple />
        </Box>
      </>
    );
  },
);

const EmailStepBody: React.FC<EmailStepProps> = observer(
  ({ showContinueWithSsoLink, children }) => {
    const {
      rootStore: {
        displayStore: { socialButtonsWidth, sideBySideForm, isMobile },
        ssoStore: { isSSOEnabledForFunnel },
        emailStepStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <>
        {children}
        <AuthDivider
          className={classNames({ [authPageStyle.horizontal]: !isMobile })}
        />
        <Box
          gap={2}
          direction="vertical"
          align="right"
          width={sideBySideForm.width}
          verticalAlign="middle"
        >
          <SocialAuth />
          {showContinueWithSsoLink && isSSOEnabledForFunnel && (
            <Box width={socialButtonsWidth} align="center">
              <TextButton
                className={s.a11yFocus}
                onClick={emailStepStore.onClickContinueWithSso}
                size="small"
                underline="none"
                dataHook={dataHooks.emailStep.continueToSsoLink}
              >
                {t(keys['emailStep.sso.button'])}
              </TextButton>
            </Box>
          )}
        </Box>
      </>
    );
  },
);

const EmailStepVerticalBody: React.FC<EmailStepProps> = observer(
  ({ showContinueWithSsoLink, children }) => {
    const {
      rootStore: {
        displayStore: { socialButtonsWidth, isMobile },
        emailStepStore: { loginEmailStepStore, onClickContinueWithSso },
        ssoStore: { isSSOEnabledForFunnel },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();

    return (
      <AuthPageContextProvider.Provider value={{ store: loginEmailStepStore }}>
        <Box
          direction="vertical"
          width="100%"
          align="center"
          marginBottom={!isMobile ? '40px' : undefined}
          dataHook={dataHooks.emailStep.container}
        >
          <AuthPage
            container={{
              dataHook: dataHooks.login.container,
              mainHeaderProps: {
                showBackButton: false,
              },
            }}
          >
            <BodyContainer className={s.vertical} withPolicies>
              <AuthPage.Header dataHooks={dataHooks.signup}>
                <LoginSubTitle flow={LOGIN_FLOWS.DEFAULT} />
              </AuthPage.Header>
              <AuthPage.VerticalBody
                socialFirst={false}
                dividerText={t(keys['emailStep.wixel.or.login.with'])}
              >
                <Box marginBottom={2} width="320px">
                  {children}
                </Box>
              </AuthPage.VerticalBody>
              {!showContinueWithSsoLink && isSSOEnabledForFunnel && (
                <Box width={socialButtonsWidth} align="center">
                  <TextButton
                    className={s.a11yFocus}
                    onClick={onClickContinueWithSso}
                    size="small"
                    underline="none"
                    dataHook={dataHooks.emailStep.continueToSsoLink}
                  >
                    {t(keys['emailStep.sso.button'])}
                  </TextButton>
                </Box>
              )}
              <PoliciesVerticalContainer>
                <PoliciesContainer.Minimal />
              </PoliciesVerticalContainer>
            </BodyContainer>
          </AuthPage>
          <AuthPage.Policies />
        </Box>
      </AuthPageContextProvider.Provider>
    );
  },
);

const MobileEmailStepSocialFirst: React.FC<EmailStepProps> = observer(
  ({ showContinueWithSsoLink, children }) => {
    const {
      rootStore: {
        displayStore: { socialButtonsWidth, sideBySideForm, isMobile },
        ssoStore: { isSSOEnabledForFunnel },
        emailStepStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <>
        <Box
          gap={2}
          direction="vertical"
          align="right"
          width={sideBySideForm.width}
          verticalAlign="middle"
        >
          <SocialAuth />
          {showContinueWithSsoLink && isSSOEnabledForFunnel && (
            <Box width={socialButtonsWidth} align="center">
              <TextButton
                className={s.a11yFocus}
                onClick={emailStepStore.onClickContinueWithSso}
                size="small"
                underline="none"
                dataHook={dataHooks.emailStep.continueToSsoLink}
              >
                {t(keys['emailStep.sso.button'])}
              </TextButton>
            </Box>
          )}
        </Box>
        <AuthDivider
          text={t(keys['login.divider.mobile'])}
          className={classNames({ [authPageStyle.horizontal]: !isMobile })}
        />
        {children}
      </>
    );
  },
);

const _EmailStep: React.FC<EmailStepProps> = observer(
  ({ showForgotEmailLink }) => {
    const {
      rootStore: {
        displayStore: {
          socialButtonsWidth,
          sideBySideForm,
          isMobile,
          isVerticalLayout,
          preset,
          isWixel,
        },
        emailStepStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();

    useEffect(() => {
      emailStepStore.emailField.clear();
    }, []);

    const inputBorder = isVerticalLayout ? 'standard' : 'bottomLine';
    const buttonSkin =
      preset.login?.primaryButtonDesign?.skin ??
      (emailStepStore.isEmailLoginMobileMode ? 'standard' : 'inverted');
    return (
      <Box width={sideBySideForm.width} paddingBottom={0}>
        <Box width={socialButtonsWidth} direction="vertical" paddingBottom={0}>
          {isMobile &&
          !emailStepStore.isEmailLoginMobileMode &&
          !isVerticalLayout ? (
            <InputFieldWithLabel
              label={t(keys['emailStep.email.label'])}
              type="email"
              marginBottom={0}
              wrapperClassName={s.emailInput}
              dataHook={dataHooks.emailStep.emailInput}
              border={inputBorder}
              onFocus={() => {
                emailStepStore.isEmailLoginMobileMode = true;
              }}
            />
          ) : (
            <>
              <InputFieldWithLabel
                required
                autoFocus
                {...(isWixel && {
                  className: classNames(s.inputField, s.wixel),
                })}
                formField={emailStepStore.emailField}
                ariaLabel={t(keys['emailStep.signup.or.login'])}
                label={t(keys['emailStep.email.label'])}
                type="text"
                name="email"
                autocomplete="email"
                marginBottom={isMobile && !isVerticalLayout ? 2 : 0}
                wrapperClassName={s.emailInput}
                dataHook={dataHooks.emailStep.emailInput}
                border={inputBorder}
              />
              <Recaptcha />
              <Box
                direction="vertical"
                align={
                  !emailStepStore.isEmailLoginMobileMode ? 'left' : undefined
                }
                gap={emailStepStore.isEmailLoginMobileMode ? 0 : 4}
              >
                {showForgotEmailLink && (isVerticalLayout || !isMobile) && (
                  <ThemedTextButton
                    className={s.a11yFocus}
                    onClick={emailStepStore.onClickForgotEmail}
                    size="small"
                    underline="always"
                    dataHook={dataHooks.emailStep.forgotEmailLink}
                    as="a"
                    href={emailStepStore.getAccountRecoveryLink('email')}
                  >
                    {t(keys['emailStep.forgotEmail.button'])}
                  </ThemedTextButton>
                )}
                <ButtonWithLoader
                  className={classNames(s.submitButton, s.a11yFocus, s.border)}
                  onClick={() => emailStepStore.submit()}
                  showLoader={emailStepStore.isLoading}
                  showArrowOnOver={isVerticalLayout}
                  dataHook={dataHooks.emailStep.submitButton}
                  type="submit"
                  fullWidth={
                    emailStepStore.isEmailLoginMobileMode ||
                    preset.login?.primaryButtonDesign?.fullWidth
                  }
                  skin={buttonSkin}
                  suffixIcon={
                    !emailStepStore.isLoading ? <ChevronRight /> : undefined
                  }
                >
                  {t(keys['emailStep.submit'])}
                </ButtonWithLoader>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  },
);

const getEmailStepBody = (
  showForgotEmailLink: boolean,
  showContinueWithSsoLink: boolean,
  isEmailLoginMobileMode: boolean,
  isMobile: boolean,
) => {
  if (isEmailLoginMobileMode) {
    return (
      <MobileEmailStepSocialLast>
        <_EmailStep showForgotEmailLink={false} />
      </MobileEmailStepSocialLast>
    );
  }
  if (isMobile) {
    return (
      <MobileEmailStepSocialFirst
        showContinueWithSsoLink={showContinueWithSsoLink}
      >
        <_EmailStep showForgotEmailLink={false} />
      </MobileEmailStepSocialFirst>
    );
  }

  return (
    <EmailStepBody showContinueWithSsoLink={showContinueWithSsoLink}>
      <_EmailStep showForgotEmailLink={showForgotEmailLink} />
    </EmailStepBody>
  );
};

const EmailStepWrapper: React.FC<EmailStepProps> = observer(
  ({
    children,
    policiesElement,
    showForgotEmailLink = false,
    showContinueWithSsoLink = false,
  }) => {
    const {
      rootStore: {
        displayStore: {
          isFullScreenMode,
          sideBySideForm,
          authFormWidth,
          isMobile,
        },
        emailStepStore: { isEmailLoginMobileMode },
        ssoStore: { isSSOEnabledForFunnel },
      },
    } = useContext(AppContextProvider);
    const shouldShoeContinueWithSsoLink =
      showContinueWithSsoLink && isSSOEnabledForFunnel;
    return (
      <Box
        direction="vertical"
        alignContent="space-between"
        align="center"
        height="100%"
        width="100%"
        marginBottom={!isMobile ? '40px' : undefined}
      >
        <DialogFormContainer
          dataHook={dataHooks.emailStep.container}
          mainHeaderProps={{ showBackButton: false }}
        >
          {children}
          <Box
            direction={sideBySideForm.direction}
            maxWidth={authFormWidth}
            width="100%"
            height={sideBySideForm.height('small')}
          >
            {getEmailStepBody(
              showForgotEmailLink,
              shouldShoeContinueWithSsoLink,
              isEmailLoginMobileMode,
              isMobile,
            )}
          </Box>
        </DialogFormContainer>
        <Box
          align="center"
          direction="vertical"
          width="100%"
          className={classNames(s.policiesContainer, {
            [s.modalPolicies]: !isFullScreenMode,
          })}
        >
          <PoliciesContainer>
            {policiesElement || <PoliciesContainer.Expanded />}
          </PoliciesContainer>
        </Box>
      </Box>
    );
  },
);

const EmailStep = Object.assign(EmailStepWrapper, {
  EmailStepHeader,
});

const LoginEmailStepDialog: React.FC = observer(() => {
  const {
    rootStore: {
      loginStore,
      emailStepStore: { loginEmailStepStore, signupEmailStepStore },
      displayStore: { isVerticalLayout, isWixel },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  if (isVerticalLayout) {
    return (
      <EmailStepVerticalBody>
        <_EmailStep showForgotEmailLink={true} />
      </EmailStepVerticalBody>
    );
  }

  return (
    <EmailStepContextProvider.Provider value={{ store: loginEmailStepStore }}>
      <EmailStep
        showForgotEmailLink={true}
        showContinueWithSsoLink={true}
        policiesElement={<PoliciesContainer.Minimal />}
      >
        <EmailStep.EmailStepHeader>
          {loginEmailStepStore.subtitleKey && (
            <DialogHeader.SubTitleSmall>
              {t(keys[loginEmailStepStore.subtitleKey])}
            </DialogHeader.SubTitleSmall>
          )}
          {!loginStore.shouldBlockEditorXSignup() && (
            <DialogHeader.SubTitleSmall id={s.loginSubtitle}>
              <Trans i18nKey={keys['emailStep.login.subTitle']}>
                Dont have an account?
                <ThemedTextButton
                  className={classNames(s.link, {
                    [s.wixel]: isWixel,
                  })}
                  onClick={() => {
                    signupEmailStepStore.onShowSignupEmailStep();
                    loginStore.reportSwitchToSignup();
                  }}
                  underline={isVerticalLayout ? 'always' : 'onHover'}
                  dataHook={dataHooks.loginEmailStep.switchToSignupBtn}
                  skin="standard"
                >
                  Sign Up
                </ThemedTextButton>
              </Trans>
            </DialogHeader.SubTitleSmall>
          )}
        </EmailStep.EmailStepHeader>
      </EmailStep>
    </EmailStepContextProvider.Provider>
  );
});

export const EmailStepDialog: React.FC = observer(() => {
  const location = useLocation();
  const {
    rootStore: {
      emailStepStore: { isFetchingByDefaultEmail, loginEmailStepStore },
    },
  } = useContext(AppContextProvider);
  const customizedEmailStep = loginEmailStepStore.showLoginEmailStep ? (
    <LoginEmailStepDialog />
  ) : (
    <Redirect to={ROUTES.SIGNUP_PASSWORD + location.search} />
  );
  if (isFetchingByDefaultEmail) {
    return (
      <Box width="100%" height="100%" align="center" verticalAlign="middle">
        <Loader />
      </Box>
    );
  }
  return customizedEmailStep;
});
