import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import s from './ResetPasswordConfirmation.scss';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { dataHooks } from '../../dataHooks';
import { Box } from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import { keys } from '../../translationsKeys';
import { DialogContainer } from '../DialogContainer';
import { SubmitButton } from '../SubmitButton';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import { BodyContainer } from '../AuthPage/AuthPage';

export const ResetPasswordConfirmation: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      resetPasswordStore,
      displayStore: { isVerticalLayout },
      navigationStore
    },
  } = useContext(AppContextProvider);
  return (
    <Box direction="vertical" width="100%" align="center">
      <DialogContainer
        dataHook={dataHooks.resetPasswordConfirmation.container}
        mainHeaderProps={{ showBackButton: false }}
        className={s.resetPasswordConfirmationContainer}
      >
        <BodyContainer className={s.resetPasswordConfirmationBody}>
          <DialogHeader className={s.resetPasswordConfirmationHeader}>
            <DialogHeader.Title as="h3">
              {t(keys['reset_password_confirmation.title'])}
            </DialogHeader.Title>
          </DialogHeader>
          <Box
            direction="vertical"
            className={s.resetPasswordConfirmationContent}
            textAlign="center"
          >
            <ThemedText size={isVerticalLayout ? 'small' : 'medium'}>
              {t(keys['reset_password_confirmation.description'])}
            </ThemedText>
            {resetPasswordStore.isRecoveryWithCode() && (
              <ThemedText
                weight="bold"
                size={isVerticalLayout ? 'small' : 'medium'}
              >
                {t(
                  keys[
                    'reset_password_confirmation.recovery_with_code.description'
                  ]
                )}
              </ThemedText>
            )}
            <Box
              marginTop={6}
              direction="vertical"
              gap="5px"
              align="center"
              width={isVerticalLayout ? '100%' : 'auto'}
            >
              <SubmitButton
                onClick={resetPasswordStore.onResetPasswordConfirmationSubmit}
                skin="standard"
              >
                {t(
                  resetPasswordStore.isRecoveryWithCode()
                    ? 'reset_password_confirmation.recovery_with_code.submit'
                    : 'reset_password_confirmation.submit'
                )}
              </SubmitButton>
              {!isVerticalLayout && resetPasswordStore.isRecoveryWithCode() && (
                <ThemedTextButton onClick={navigationStore.navigateToLogin} as="a">
                  {t(
                    keys[
                      'reset_password_confirmation.recovery_with_code.secondary_action'
                    ]
                  )}
                </ThemedTextButton>
              )}
            </Box>
          </Box>
        </BodyContainer>
      </DialogContainer>
    </Box>
  );
});
