import {
  IPasswordInputRef,
  PasswordInput,
  PasswordInputProps,
} from '@wix/identity-fed-common';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useContext, useRef } from 'react';
import { InputFieldProps } from '../InputField/InputField';
import { PasswordFormField } from '../../stores/passwordFormField';
import { useTranslation } from 'react-i18next';
import s from './PassworField.scss';
import { AppContextProvider } from '../AppLoader';
import classNames from "classnames";

export const PasswordField: FC<
  PasswordInputProps & {
    formField?: PasswordFormField;
  }
> = observer(({ showStrength, formField, passwordPolicy, ...props }) => {
  const passwordRef = useRef<IPasswordInputRef>(null);
  const { t } = useTranslation();
  const { rootStore } = useContext(AppContextProvider);

  formField?.setPasswordRef(passwordRef.current!);

  const errorText = formField?.serverError || formField?.errorInfo;
  const showError = formField?.isDirty && formField?.isTouched && errorText;
  const status = formField?.isWarning ? 'warning' : 'error';

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formField?.onChange(e);
      props?.onChange?.(e);
    },
    [props]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      try {
        formField?.onBlur();
        props?.onBlur?.(e);
      } catch (e: any) {
        passwordRef?.current?.setError?.(
          t(`passwordPolicy.serverError.${e.message}`)
        );
      }
    },
    [props, t]
  );

  return (
    <PasswordInput
      {...props}
      ref={passwordRef}
      onBlur={onBlur}
      onChange={onChange}
      status={showError ? status : undefined}
      statusMessage={showError ? errorText : undefined}
      showLoader={formField?.loader}
      readOnly={formField?.loader}
      showVisibility={formField?.value.length! > 0}
      showStrength={showStrength}
      passwordPolicy={passwordPolicy}
      locale={rootStore.language.locale}
      withTheme={true}
    />
  );
});

export const PasswordFieldWithLabel: React.FC<
  Omit<InputFieldProps, 'type'> & {
    formField?: PasswordFormField;
  }
> = observer((props) => {
  const { rootStore: {displayStore: {isWixel}} } = useContext(AppContextProvider);

  return (<div className={classNames(s.passwordField, {
    [s.wixel]: isWixel,
  })}>
    <PasswordField {...props} inputWithLabel border={props.border || "bottomLine"}/>
  </div>)
});
