import { observable, makeObservable, computed, action } from 'mobx';
import MobileDetect from 'mobile-detect';
import { v4 as uuidv4 } from 'uuid';
import { RootStore } from './root';
import {
  PRESETS,
  ViewPreset,
  ViewPresets,
  SocialProviders,
  DEFAULT_LOGO_HEIGHT,
} from '../utils/presets';
import { merge } from 'lodash';
import {
  SEARCH_PARAMS,
  STUDIO_HOME_URL,
  WIX_HOME_URL,
} from '../utils/constants';
import wixLogo from '../assets/images/wixLogo.svg?url';
import wixStudioLogo from '../assets/images/wixStudioLogo.svg?url';
import { EXPERIMENTS } from './constants';

type IDirection = 'horizontal' | 'vertical';

interface IField {
  direction: IDirection;
  width: string;
  height: (size: 'small' | 'high') => string | undefined;
}

const defaultViewPreset = (isStudio = false): ViewPreset => ({
  nameKey: 'presets.names.wix',
  skin: 'standard',
  logo: {
    src: isStudio ? wixStudioLogo : wixLogo,
    position: 'left',
    height: DEFAULT_LOGO_HEIGHT,
    mobile: {
      height: '20px',
    },
    alt: 'Wix',
    url: isStudio ? STUDIO_HOME_URL : WIX_HOME_URL,
  },
  buttonDesign: {
    skin: 'standard',
    fullWidth: false,
  },
  socialProviders: `${SocialProviders.GOOGLE},${SocialProviders.FACEBOOK},${SocialProviders.APPLE}`,
  titleCustomFontSize: {
    authPage: {
      desktop: 'xxLarge',
      mobile: 'xLarge',
    },
    secondaryFlow: {
      desktop: 'large',
      mobile: 'medium',
    },
  },
  signup: {
    flows: [
      {
        id: 'domains',
        title: {
          key: 'signup.flows.domains.title',
          size: 'medium',
        }
      },
    ]
  },
  policies: {
    policyKey: 'policies_section.signup_content',
  },
});

const MAP_VERTICAL_PATH_TO_PRESET = {
  logo: PRESETS.LOGO_MAKER,
  [PRESETS.DAYFUL]: PRESETS.DAYFUL,
  [PRESETS.CODUX]: PRESETS.CODUX,
  [PRESETS.HOPP]: PRESETS.HOPP,
  [PRESETS.WIXEL]: PRESETS.WIXEL,
};

export enum AppViewMode {
  FULL_SCREEN = 'FULL_SCREEN',
  MODAL = 'MODAL',
  COMPONENT = 'COMPONENT',
}

export class DisplayStore {
  private readonly rootStore: RootStore;
  private onResizeListeners: Map<string, (location: Location) => any>;
  public userAget = window.navigator.userAgent;
  public mobileDetect = new MobileDetect(window.navigator.userAgent);
  public screenInnerWidth: number;
  public viewMode: AppViewMode;
  public preset: ViewPreset;
  public presetKey: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      screenInnerWidth: observable,
      userAget: observable,
      mobileDetect: observable,
      viewMode: observable,
      preset: observable,
      presetKey: observable,
      isMobile: computed,
      showMainHeader: computed,
      dialogContainerClass: computed,
      showModalHeader: computed,
      isEditorX: computed,
      authFormWidth: computed,
      direction: computed,
      socialButtonsWidth: computed,
      sideBySideForm: computed,
      updateScreenSize: action,
    });
    this.setOnResizeListener();
    this.updateScreenSize();
    this.init();
  }

  get isMobile(): boolean {
    return (
      !!(
        this.mobileDetect.mobile() ||
        this.mobileDetect.phone() ||
        this.mobileDetect.tablet()
      ) || this.screenInnerWidth <= 810
    );
  }

  get isIframe(): boolean {
    return window.self !== window.top;
  }

  // We can use computed here because it's relevant for all pages and so will always affect the view
  get showMainHeader(): boolean {
    return this.viewMode === AppViewMode.FULL_SCREEN;
  }

  get showModalHeader(): boolean {
    return !this.isFullScreenMode;
  }

  get authFormWidth(): string {
    if (!this.isMobile) {
      return '774px';
    }
    return this.isVerticalLayout && this.screenInnerWidth <= 380
      ? '270px'
      : '340px';
  }

  get socialButtonsWidth(): string {
    return this.isMobile ? '100%' : '320px';
  }

  get direction(): IDirection {
    return this.isMobile || this.isVerticalLayout ? 'vertical' : 'horizontal';
  }

  get isVerticalLayout(): boolean {
    return this.preset.container?.layout === 'vertical';
  }

  get isWixel(): boolean {
    return this.presetKey === PRESETS.WIXEL;
  }

  get sideBySideForm(): IField {
    return {
      direction: this.direction,
      width: '100%',
      height: (size: 'small' | 'high') => {
        if (this.isMobile) {
          return undefined;
        }
        return size === 'small' ? '230px' : '354px';
      },
    };
  }

  get dialogContainerClass(): string {
    return this.isFullScreenMode
      ? 'fullScreenViewContainer'
      : 'modalViewContainer';
  }

  get isEditorX(): boolean {
    return this.presetKey === PRESETS.EDITORX;
  }

  get isWixEducation(): boolean {
    return (
      this.presetKey === PRESETS.EDUCATION_STUDENT ||
      this.presetKey === PRESETS.EDUCATION_TEACHER
    );
  }

  get isFullScreenMode(): boolean {
    return this.viewMode === AppViewMode.FULL_SCREEN;
  }

  public extractBottomPadding(padding: string): number | undefined {
    if (!padding) {
      return undefined;
    }
    try {
      const currentPadding = padding.split(' ');
      if (currentPadding.length !== 4) {
        return undefined;
      }
      const currentBottomPadding = parseInt(currentPadding[2], 10);
      return currentBottomPadding;
    } catch (e) {
      return undefined;
    }
  }

  public updateScreenSize() {
    if (this.userAget !== window.navigator.userAgent) {
      this.mobileDetect = new MobileDetect(window.navigator.userAgent);
      this.userAget = window.navigator.userAgent;
    }
    this.screenInnerWidth = window.innerWidth;
  }

  public getPresetHeaders() {
    switch (this.presetKey) {
      case PRESETS.WIXEL:
        return { 'x-wix-brand': PRESETS.WIXEL };
      case PRESETS.DAYFUL:
        return { 'x-wix-brand': PRESETS.DAYFUL };
      default:
        if (this.rootStore.isStudio) {
          return { 'x-wix-brand': 'studio' };
        }
        return {};
    }
  }

  registerOnResize(callback: (location: Location) => any) {
    const callbackId = uuidv4();
    this.onResizeListeners.set(callbackId, callback);
    return callbackId;
  }

  unRegisterOnResize(callbackId: string) {
    this.onResizeListeners.delete(callbackId);
  }

  private setOnResizeListener() {
    if (!this.onResizeListeners) {
      this.onResizeListeners = new Map();
      this.registerOnResize(this.updateScreenSize.bind(this));
    }
    window.addEventListener('resize', () =>
      this.onResizeListeners.forEach((value) => {
        value(location);
      }),
    );
  }

  private init() {
    this.initViewMode();
    this.initPreset();
    this.initFlow();
    this.initCssVariables();
  }

  private initViewMode() {
    const viewMode: AppViewMode = this.rootStore.navigationStore.getQueryParam(
      'viewMode',
    ) as AppViewMode;
    const isIframe = window.self !== window.top;
    // The default screen view mode for iframe is 'MODAL'
    const defaultViewMode = isIframe
      ? AppViewMode.MODAL
      : AppViewMode.FULL_SCREEN;
    this.viewMode = viewMode ? viewMode : defaultViewMode;
  }

  private initFlow() {
    const flow = this.rootStore.navigationStore.getQueryParam('flow');
    const loginFlow = this.preset.login?.flows?.find(
      (_flow) => _flow.id === flow,
    );
    if (loginFlow && this.preset.login) {
      this.preset.login = Object.assign(this.preset.login, loginFlow);
    }
    const signupFlow = this.preset.signup?.flows?.find(
      (_flow) => _flow.id === flow,
    );
    if (signupFlow && this.preset.signup) {
      this.preset.signup = Object.assign(this.preset.signup, signupFlow);
    }
  }

  private isPresetAllowed(presetKey: string) {
    switch (presetKey) {
      case PRESETS.WIXEL:
        return this.rootStore.experiments.enabled(
          EXPERIMENTS.SHOULD_ENABLE_WIXEL,
        );
      default:
        return true;
    }
  }

  private initPreset() {
    const { getQueryParam, baseDomain } = this.rootStore.navigationStore;
    const { userType, isStudio } = this.rootStore;
    let presetKey =
      getQueryParam('preset') ||
      getQueryParam('customized') ||
      getQueryParam(SEARCH_PARAMS.COLOR);
    if (!presetKey) {
      // Special handling for editorX
      if (baseDomain?.includes(PRESETS.EDITORX)) {
        presetKey = PRESETS.EDITORX;
      } else if (userType && MAP_VERTICAL_PATH_TO_PRESET[userType]) {
        presetKey = MAP_VERTICAL_PATH_TO_PRESET[userType];
      } else {
        let splited = origin.split(`${baseDomain}/`);
        splited = splited?.[1]?.split('/');
        presetKey = splited?.[0];
      }
    }

    let preset = {};
    if (presetKey) {
      if (this.isPresetAllowed(presetKey)) {
        // We also try to use the lower/upper case of the preset because in some cases we support both.
        // for example in hopp - "hopp" is extracted from the url and "HOPP" is supplied
        // as a "color" parameter
        preset =
          ViewPresets[presetKey] ||
          ViewPresets[presetKey.toLowerCase()] ||
          ViewPresets[presetKey.toUpperCase()] ||
          {};
      }
    }
    this.presetKey = presetKey;
    this.preset = merge(defaultViewPreset(isStudio), preset);
  }

  initCssVariables() {
    if (this.preset.cssVariables) {
      const cssVars = Object.entries(this.preset.cssVariables);
      cssVars.forEach(([name, value]) => {
        document.documentElement.style.setProperty(name, value);
      });
    }
  }
}
